import { IconDraw24 } from '@cian/ui-kit';
import { IconActionFingerDraw16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';
import { finishDrawing, startDrawing } from 'shared/map-search/actions/drawing';
import { clearGeoType } from 'shared/map-search/actions/jsonQuery';
import { MapLayer } from 'shared/map-search/components/MapLayer';
import { OnboardingTooltip } from 'shared/map-search/components/OnboardingTooltip';
import { preparePolygons } from 'shared/map-search/mappers/polygons';
import { TThunkDispatch, IApplicationState } from 'shared/map-search/types/redux';

import { DrawButton } from './DrawButton';
import { COOKIE_NAME } from './constants';
import * as styles from './styles.css';
import { trackAreaSelect } from './tracking';
import { setOnboardingCloseCookie } from './utils';

export const DrawButtonContainer: React.FC = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const isDrawingMode = useSelector((state: IApplicationState) => state.drawing.isDrawingMode);
  const polygons = useSelector((state: IApplicationState) => preparePolygons(state.filters.jsonQuery));
  const [shouldShowOnboarding, setShouldShowOnboarding] = React.useState(false);
  const onboardingTimeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);
  const hasPolygons = polygons.length > 0;
  const isActive = (!hasPolygons && isDrawingMode) || shouldShowOnboarding;

  const hideOnboarding = React.useCallback(() => {
    setShouldShowOnboarding(false);
    setOnboardingCloseCookie();
  }, []);

  const showOnboarding = React.useCallback(() => {
    setShouldShowOnboarding(true);

    onboardingTimeoutRef.current = setTimeout(hideOnboarding, 5000);
  }, [hideOnboarding]);

  const handleActivateClick = React.useCallback(() => {
    hideOnboarding();

    if (!isDrawingMode) {
      dispatch(startDrawing());
      trackAreaSelect('start');
    } else {
      dispatch(finishDrawing());
      trackAreaSelect('close');
    }
  }, [dispatch, hideOnboarding, isDrawingMode]);

  const handleCancelClick = React.useCallback(() => {
    hideOnboarding();

    dispatch(finishDrawing());
    dispatch(clearGeoType('polygon'));
    trackAreaSelect('close');
  }, [dispatch, hideOnboarding]);

  React.useEffect(() => {
    if (document.cookie.indexOf(COOKIE_NAME) === -1) {
      onboardingTimeoutRef.current = setTimeout(showOnboarding, 2000);
    }

    return () => {
      if (onboardingTimeoutRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        clearTimeout(onboardingTimeoutRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Desktop>
        <OnboardingTooltip
          tooltip="Выделяйте интересующую вас область на карте"
          open={shouldShowOnboarding}
          onClose={hideOnboarding}
        >
          <DrawButton
            active={isActive}
            hasPolygons={hasPolygons}
            onActivateClick={handleActivateClick}
            onCancelClick={handleCancelClick}
            icon={<IconDraw24 color="current_color" />}
          />
        </OnboardingTooltip>
      </Desktop>
      <NotDesktop>
        <MapLayer className={styles['layer']}>
          <DrawButton
            active={isActive}
            hasPolygons={hasPolygons}
            onActivateClick={handleActivateClick}
            onCancelClick={handleCancelClick}
            size="L"
            icon={<IconActionFingerDraw16 color="current_color" />}
          />
        </MapLayer>
      </NotDesktop>
    </>
  );
};
