import { TUser } from 'shared/map-search/types/user';

import { TTrackingUser } from '../../types/cianAnalyticsPageViewObject';

export const getTrackingUser = (user: TUser, regionId?: null | number): TTrackingUser => {
  const trackingUser: { extra: { defaultRegionId?: number | null } } = {
    extra: { defaultRegionId: regionId },
  };

  if (user && user.isAuthenticated) {
    return {
      ...trackingUser,
      userId: user.userId,
      extra: { defaultRegionId: regionId },
    };
  }

  return trackingUser;
};
