import { ca } from '@cian/analytics';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

interface ITrackBoundsChangeParams {
  jsonQuery: IJsonQuery;
}

export function trackBoundsChange({ jsonQuery }: ITrackBoundsChangeParams) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'bounds_change',
    category: 'Map',
    sc: jsonQuery,
  });
}
