import { IconActionPlus16, IconActionPlus24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';
import { useMapContext } from 'shared/map-search/containers/Map/context';

import { ZoomInButton } from './ZoomInButton';

export const ZoomInButtonContainer: React.FC = props => {
  const { map } = useMapContext();

  return (
    <>
      <Desktop>
        <ZoomInButton {...props} map={map} size="M" icon={<IconActionPlus24 color="current_color" />} />
      </Desktop>
      <NotDesktop>
        <ZoomInButton {...props} map={map} size="L" icon={<IconActionPlus16 color="current_color" />} isMobile />
      </NotDesktop>
    </>
  );
};
