import { ca } from '@cian/analytics';

import { INewbuilding } from 'shared/map-search/types/map/newbuilding';

interface ITrackPinClickParameters {
  zoom: number;
  newbuilding: INewbuilding;
  hasDiscountOffers: boolean;
}

export function trackPinClick({ zoom, newbuilding, hasDiscountOffers }: ITrackPinClickParameters) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click_pin',
    category: 'Map',
    label: `green/${zoom}`,
    products: [
      {
        extra: {
          newbuilding_id: newbuilding.id,
          is_promo: true,
          is_price_with_discount: hasDiscountOffers,
        },
      },
    ],
  });
}
