import { ca } from '@cian/analytics';
import { TDevice } from '@cian/utils';

import { getTrackingUser } from 'node/map-search/utils/analytics/getTrackingUser';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';
import { TUser } from 'shared/map-search/types/user';
import { getSiteType } from 'shared/map-search/utils/analytics';

export function trackClearClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'clean_filters',
    category: 'Map',
  });
}

export function trackAdvancedFiltersClick() {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Click',
    category: 'Map',
    label: 'Filters',
  });
}

interface ITrackFilterApply {
  jsonQuery: IJsonQuery;
  queryString?: string;
}

export function trackApplyClick({ jsonQuery, queryString }: ITrackFilterApply) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'button_search',
    label: queryString,
    category: 'Map',
    sc: jsonQuery,
  });
}

export function trackQuickFilterApply({ jsonQuery, queryString }: ITrackFilterApply) {
  ca('eventSite', {
    name: 'oldevent',
    action: 'fast_filters_search',
    category: 'Map',
    label: queryString,
    sc: jsonQuery,
  });
}

interface ITrackPageViewParams {
  deviceType: TDevice;
  user: TUser;
  jsonQuery: IJsonQuery;
  regionId?: null | number;
}

export function trackPageView({ jsonQuery, user, deviceType, regionId }: ITrackPageViewParams) {
  ca('pageview', {
    category: 'page',
    action: 'newpage',
    name: 'newpage',
    label: 'open',
    sc: jsonQuery,
    user: getTrackingUser(user, regionId),
    page: {
      pageType: 'Map',
      siteType: getSiteType(deviceType),
    },
  });
}
