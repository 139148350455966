import { IconActionMinus24, IconActionMinus16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';
import { useMapContext } from 'shared/map-search/containers/Map/context';

import { ZoomOutButton } from './ZoomOutButton';

export const ZoomOutButtonContainer: React.FC = props => {
  const { map } = useMapContext();

  return (
    <>
      <Desktop>
        <ZoomOutButton {...props} map={map} size="M" icon={<IconActionMinus24 color="current_color" />} />
      </Desktop>
      <NotDesktop>
        <ZoomOutButton {...props} map={map} size="L" icon={<IconActionMinus16 color="current_color" />} isMobile />
      </NotDesktop>
    </>
  );
};
