import { IconRuler24 } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';
import { disableRuler, enableRuler } from 'shared/map-search/actions/ruler';
import { ControlButton } from 'shared/map-search/components/ControlButton';
import { useMapContext } from 'shared/map-search/containers/Map/context';
import { IApplicationState } from 'shared/map-search/types/redux';

import * as styles from './RulerButton.css';
import { trackRulerClick } from './tracking';

export const RulerButtonContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { map } = useMapContext();
  const isRulerEnabled = useSelector((state: IApplicationState) => state.ruler.isEnabled);

  const handleClick = React.useCallback(() => {
    if (!isRulerEnabled) {
      dispatch(enableRuler());
    } else {
      dispatch(disableRuler());
    }

    trackRulerClick();
  }, [dispatch, isRulerEnabled]);

  React.useEffect(() => {
    if (isRulerEnabled) {
      map.behaviors.enable(['ruler']);
    } else {
      map.behaviors.disable(['ruler']);
    }

    return () => {
      map.behaviors.disable(['ruler']);
    };
  }, [map, isRulerEnabled]);

  return (
    <>
      <Desktop>
        <ControlButton active={isRulerEnabled} tooltip="Линейка" onClick={handleClick}>
          <IconRuler24 color="current_color" />
        </ControlButton>
      </Desktop>
      <NotDesktop>
        <ControlButton
          // TODO: удалить когда будет иконка линейки 16px
          className={styles['shrink_icon']}
          active={isRulerEnabled}
          tooltip="Линейка"
          onClick={handleClick}
          size="L"
        >
          <IconRuler24 color="current_color" />
        </ControlButton>
      </NotDesktop>
    </>
  );
};
