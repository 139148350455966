import { INewbuilding } from '../../types/map/newbuilding';

interface IParams {
  newbuilding: INewbuilding | null;
  hasDiscountOffers: boolean;
}

interface IExtra {
  newbuilding_id?: number;
  house_id?: number;
  is_promo?: true;
  is_price_with_discount: boolean;
}

export function getPinProducts({ newbuilding, hasDiscountOffers }: IParams) {
  if (!newbuilding) {
    return undefined;
  }

  let extra: IExtra = {
    is_price_with_discount: hasDiscountOffers,
  };

  if (newbuilding.id) {
    extra = {
      ...extra,
      newbuilding_id: newbuilding.id,
    };
  }

  if (newbuilding.houseId) {
    extra = {
      ...extra,
      house_id: newbuilding.houseId,
    };
  }

  if (newbuilding.isAnyFicheringPlus) {
    extra = {
      ...extra,
      is_promo: true,
    };
  }

  return [{ extra }];
}
