import classNames from 'clsx';
import * as React from 'react';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';

import * as styles from './Controls.css';
import { INDENT_TO_CLASS } from './constants';
import { IControlsProps } from './types';

export const Controls: React.FC<IControlsProps> = ({
  ruler,
  drawPolygon,
  help,
  zoomIn,
  zoomOut,
  geoLocation,
  indent = 'M',
}) => {
  return (
    <>
      <NotDesktop>
        <div className={classNames(styles['container'], INDENT_TO_CLASS[indent], styles['middle-indent--redesign'])}>
          <div className={styles['top-group--redesign']}>
            {geoLocation && <div className={styles['layer_geo']}>{geoLocation}</div>}
            <div className={styles['layer']}>{ruler}</div>
            {drawPolygon}
          </div>
          <div className={styles['map-controls']}>
            <div className={styles['zoom']}>{zoomIn}</div>
            {zoomOut}
          </div>
        </div>
      </NotDesktop>
      <Desktop>
        <div className={classNames(styles['container'], INDENT_TO_CLASS[indent])}>
          <div className={styles['top-group']}>
            {ruler}
            {drawPolygon}
            {help}
          </div>
          <div className={styles['map-controls']}>
            {zoomIn}
            {zoomOut}
            {geoLocation && <div className={styles['geo']}>{geoLocation}</div>}
          </div>
        </div>
      </Desktop>
    </>
  );
};

Controls.displayName = 'Controls';
