import { trackBoundsChange } from 'shared/map-search/containers/Map/MapBoundsUpdater/tracking';

import { EMapBoundsActionType } from './types';
import { TMapBounds } from '../../types/mapBounds';
import { TThunkAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export const changeBounds = actionGenerator<EMapBoundsActionType.Changed, TMapBounds>(EMapBoundsActionType.Changed);

export function changeBoundsWithAnalytics(value: TMapBounds, shouldTrackBounds: boolean): TThunkAction {
  return async (dispatch, getState) => {
    const {
      filters: { jsonQuery },
    } = getState();

    dispatch(changeBounds(value));

    if (shouldTrackBounds) {
      trackBoundsChange({ jsonQuery });
    }
  };
}
