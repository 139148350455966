import { useEventCallback } from '@cian/react-utils';
import { useDeviceType } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectPromoSearchTypes } from 'shared/map-search/selectors/filters';
import { selectRegionId } from 'shared/map-search/selectors/regionId';
import { selectUser } from 'shared/map-search/selectors/user';

import { SaveSearchButtonContainer as SaveSearchButton } from './SaveSearchButton';
import { GEO_CHANGE_ACTIONS, QUICK_FILTERS_KEYS } from './constants';
import { useAppliedFilter } from './hooks';
import {
  trackAdvancedFiltersClick,
  trackApplyClick,
  trackClearClick,
  trackPageView,
  trackQuickFilterApply,
} from './tracking';
import { Filters, IOnChangeParameters, Provider, TFilter } from '../../../common/packages/Filters';
import { NonEmptyArray } from '../../../common/packages/JsonQuery';
import { IJsonQuery } from '../../../common/packages/api-models/common/json_query';
import { changeJsonQuery, fetchTags } from '../../actions/filters';
import { fetchNewbuildingPromoPins } from '../../actions/newbuildingPromoFeatures';
import { fetchClusters } from '../../actions/results';
import { IApplicationState, TThunkDispatch } from '../../types/redux';
import { IRegionSpecialPromo } from '../../types/specialPromos';
import { produceGeoChangeEvent } from '../../utils/events';
import { normalizeRegionId } from '../../utils/region';
import { getAvailableSpecialPromo } from '../../utils/specialPromos';
import { useApplicationContext } from '../ApplicationContext';

export const FiltersContainer: React.FC = () => {
  const context = useApplicationContext();
  const { microfrontendsAPI, config } = context;
  const deviceType = useDeviceType();
  const dispatch = useDispatch<TThunkDispatch>();

  const jsonQuery = useSelector<IApplicationState, IJsonQuery>(state => state.filters.jsonQuery);
  const specialPromos = useSelector<IApplicationState, IRegionSpecialPromo[]>(state => state.specialPromos);
  const queryString = useSelector<IApplicationState, string>(state => state.queryString);
  const promoSearchTypes = useSelector(selectPromoSearchTypes);
  const user = useSelector(selectUser);
  const defaultRegionId = useSelector(selectRegionId);

  const [isAwaitingTracking, setIsAwaitingTracking] = React.useState<boolean>(false);
  const isAwaitingGeoUpdate = React.useRef<boolean>(false);
  const specialPromo: IRegionSpecialPromo | undefined = getAvailableSpecialPromo(specialPromos, jsonQuery);
  const { getAppliedFilterKey, setAppliedFilter, resetAppliedFilter } = useAppliedFilter();

  const experimentalFeatures = React.useMemo(
    () => ({
      onlineBookingEnabled: Boolean(config.get<boolean>('onlineBooking.enabled')),
      searchFlatShareEnabled: Boolean(config.getStrict<boolean>('frontend_search_flat_share_enabled.Enabled')),
    }),
    [config],
  );

  const handleChange = useEventCallback((parameters: IOnChangeParameters) => {
    dispatch(changeJsonQuery({ ...parameters, prevJsonQuery: jsonQuery }));

    if (parameters.appliedModifiers.some(modifier => GEO_CHANGE_ACTIONS.includes(modifier.action))) {
      isAwaitingGeoUpdate.current = true;
      dispatch(fetchTags());
      trackPageView({
        jsonQuery: parameters.nextJsonQuery,
        user,
        deviceType,
        regionId: defaultRegionId,
      });

      if (config.get<boolean>('header.asMicrofrontend')) {
        const setRegionModifier = parameters.appliedModifiers.find(modifier => modifier.action === 'setRegion');

        if (setRegionModifier !== undefined && microfrontendsAPI) {
          const regionId = normalizeRegionId(setRegionModifier.arguments[0] as NonEmptyArray<number> | null);

          if (regionId) {
            microfrontendsAPI.header.setRegionId({ regionId });
          }
        }
      } else {
        produceGeoChangeEvent(parameters.appliedModifiers);
      }
    }
  });

  const handleApply = React.useCallback(
    (filterKey?: TFilter) => {
      dispatch(fetchTags());
      dispatch(fetchClusters({ updateBounds: isAwaitingGeoUpdate.current, deviceType }));
      dispatch(fetchNewbuildingPromoPins());
      isAwaitingGeoUpdate.current = false;

      if (filterKey) {
        setAppliedFilter(filterKey);
        setIsAwaitingTracking(true);
      }
    },
    [deviceType, dispatch, setAppliedFilter],
  );

  const handleApplyClick = React.useCallback(() => {
    setIsAwaitingTracking(true);
  }, []);

  React.useEffect(() => {
    if (isAwaitingTracking) {
      const appliedFilterKey = getAppliedFilterKey();

      if (appliedFilterKey && QUICK_FILTERS_KEYS.includes(appliedFilterKey)) {
        trackPageView({ jsonQuery, user, deviceType, regionId: defaultRegionId });
        trackQuickFilterApply({ jsonQuery, queryString });
        resetAppliedFilter();
      } else {
        trackPageView({ jsonQuery, user, deviceType, regionId: defaultRegionId });
        trackApplyClick({ jsonQuery, queryString });
      }

      setIsAwaitingTracking(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryString]);

  return (
    <Provider
      appContext={context}
      jsonQuery={jsonQuery}
      specialPromo={specialPromo}
      promoSearchTypes={promoSearchTypes}
      onChange={handleChange}
      onApply={handleApply}
      onClear={trackClearClick}
      onApplyClick={handleApplyClick}
      onAdvancedFiltersOpen={trackAdvancedFiltersClick}
      features={experimentalFeatures}
    >
      <Filters savedSearch={<SaveSearchButton />} />
    </Provider>
  );
};
