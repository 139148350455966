import { ca } from '@cian/analytics';

import { IJsonQuery } from 'shared/common/packages/api-models/common/json_query';

import { IRBushItem } from '../../types/map';

interface IParams {
  pins: IRBushItem[];
  zoom: number;
  jsonQuery: IJsonQuery;
}

export function trackPromoPinsLoadClosure() {
  const trackedPins: { [key: string]: boolean | undefined } = {};

  return function trackPromoPinsLoad({ pins, zoom, jsonQuery }: IParams) {
    const products = pins
      .map(el => {
        const newbuilding = el.feature.properties.newbuilding;
        if (!newbuilding) {
          return null;
        }

        if (trackedPins[`${newbuilding.id}_${newbuilding.houseId}`]) {
          return null;
        }

        trackedPins[`${newbuilding.id}_${newbuilding.houseId}`] = true;

        return {
          extra: {
            newbuilding_id: newbuilding.id,
            is_promo: true,
            house_id: newbuilding.houseId,
            is_price_with_discount: Boolean(el.feature.properties.hasDiscountOffers),
          },
        };
      })
      .filter(Boolean);

    if (!products.length) {
      return;
    }

    ca('eventSite', {
      name: 'oldevent',
      action: 'show_sopr_pin',
      category: 'Map',
      label: `green/${zoom}`,
      products,
      sc: jsonQuery,
    });
  };
}

export const trackPromoPinsLoad = trackPromoPinsLoadClosure();
