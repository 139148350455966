import { IconActionGeo16, IconActionGeo24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { Desktop, NotDesktop } from 'shared/common/packages/Responsive';
import { pushErrorNotification } from 'shared/map-search/actions/notifications';
import { useApplicationContext } from 'shared/map-search/containers/ApplicationContext';
import { useMapContext } from 'shared/map-search/containers/Map/context';
import { EErrorNotificationType } from 'shared/map-search/types/notifications';

import { GeoLocationButton } from './GeoLocationButton';

export const GeoLocationButtonContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { map, ymaps } = useMapContext();
  const { logger } = useApplicationContext();

  const handleGeoLocationError = React.useCallback(
    (error: Error | string) => {
      logger.error(error, { domain: 'Controls/GeoLocationButton/handleGeoLocationClick()' });

      dispatch(pushErrorNotification(EErrorNotificationType.GeoLocation));
    },
    [logger, dispatch],
  );

  return (
    <>
      <Desktop>
        <GeoLocationButton
          map={map}
          ymaps={ymaps}
          onGeoLocationError={handleGeoLocationError}
          size="M"
          icon={<IconActionGeo24 color="current_color" />}
        />
      </Desktop>
      <NotDesktop>
        <GeoLocationButton
          map={map}
          ymaps={ymaps}
          onGeoLocationError={handleGeoLocationError}
          size="L"
          icon={<IconActionGeo16 color="current_color" />}
        />
      </NotDesktop>
    </>
  );
};
